* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: #140477;
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}

.fw700 {
  font-weight: 700;
}
.fw400 {
  font-weight: 400;
}
.fs12 {
  font-size: 0.625vw;
}
.fs16 {
  font-size: 0.833vw;
}
.fs18 {
  font-size: 0.94vw;
}
.fs20 {
  font-size: 1vw;
}
.fs22 {
  font-size: 1.4vw;
}
.fs23 {
  font-size: 1.2vw;
}
.fs25 {
  font-size: 1.3vw;
}
.fs27 {
  font-size: 1.4vw;
}
.fs30 {
  font-size: 1.56vw;
}
.fs33 {
  font-size: 1.7vw;
}
.fs37 {
  font-size: 1.93vw;
}
.fs57 {
  font-size: 3vw;
}
.fs60 {
  font-size: 3.125vw;
}
.fs67 {
  font-size: 3.5vw;
}
.fs70 {
  font-size: 3.64vw;
}
.fs45 {
  font-size: 2.34vw;
}
.fs47 {
  font-size: 2.45vw;
}
.fs40 {
  font-size: 2.1vw;
}

.fs32 {
  font-size: 1.67vw;
}
.fs38 {
  font-size: 1.98vw;
}
.border-btm {
  border-bottom: solid 0.026vw #bebaba;
}

.algcenter {
  text-align: center;
}

@media (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
  /* Align center desktop only */
  .algcenter-do {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }
}

/* Mobile classes */
@media (max-width: 768px) {
  .algcenter-mo {
    text-align: center;
  }
  .fs24m {
    font-size: 6.4vw;
  }
  .fs28m {
    font-size: 7.47vw;
  }
  .fs18m {
    font-size: 4.8vw;
  }
  .fs16m {
    font-size: 4.27vw;
  }
  .fs32m {
    font-size: 8.5vw;
  }
  .fs14m {
    font-size: 3.7vw;
  }
  .fs12m {
    font-size: 3.2vw;
  }
  .fw700m {
    font-weight: 700;
  }
}
